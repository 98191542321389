/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'

// Components
import SEO from 'components/SEO'
import styled from 'styled-components'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import { Hero4, HeadingLayout, Teammate } from 'components/elements'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Blog
import BlogContainer, { BlogConsumer } from 'components/shared/BlogContainer'
import BlogOverview from 'components/elements/BlogOverview'

// Data
import postInfo from 'src/graphql/postInfo'

const BackLink = styled(Link)`
  color: ${(props) => props.theme.color.text.main};
  text-decoration: underline;
  font-size: 16px;
`
const Content = styled(ParseContent)`
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
  h3,
  a {
    color: ${(props) => props.theme.color.face.secondary};
  }
  a {
    text-decoration: underline;
  }
`

const PageTemplate = ({
  data: {
    page: { date, path, title, yoast_meta: yoast, acf },
  },
}) => {
  const blogFields = postInfo.get('vacature')

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero4
        fields={{ ...{ date, path, title, time: acf.meta.time, place: acf.meta.place, experience: acf.meta.experience }, ...acf.banner }}
      />

      <div className="container mt-5">
        <BackLink to={blogFields.path}>
          {blogFields.acf.back_button}
        </BackLink>
        <div className="my-5 d-lg-flex d-md-flex d-sm-block d-xs-block">
          <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 mb-5 pr-lg-5">
            <div className="pr-lg-5">
              <Content content={acf.story.description} />
              <ButtonDefault to={`/solliciteren?functie=${blogFields.title}`}>Solliciteren</ButtonDefault>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 pl-lg-5">
            <ParseContent content={acf.sidebar.description} />
            
            <Teammate
              id={acf.sidebar.cta}
              type={1}
            />
          </div>
        </div>
        
        <ParseContent
          className="mt-5"
          content={blogFields.acf.meta.more_posts}
        />

        <HeadingLayout className="mt-n5" heading={blogFields.acf.meta.more_posts_background_title} direction="right">
          <BlogContainer
            postType="vacature"
            settings={{
              limit: 3
            }}
          >
            <BlogConsumer>
              {({ posts }) => {
                return (
                  <>
                    <BlogOverview posts={posts} ids={acf.related.articles} />
                  </>
                )
              }}
            </BlogConsumer>
          </BlogContainer>
        </HeadingLayout>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpVacatures(wordpress_id: { eq: $wordpress_id }) {
      date(locale: "nl_NL", formatString: "DD-MM-YYYY")
      title
      path

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        story {
          description
        }

        meta {
          description
          time
          place
          experience
        }

        sidebar {
          description
          cta
        }

        related {
          articles
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
